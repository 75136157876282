<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px"/>
                <span>{{l('Global_push_settings')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-row>
                    <a-col :span="23" style="margin-left: 21px">
                        <span>{{l("Allmailwillbecconeaddressperline")}}</span>
                    </a-col>
                    <a-col :span="23" style="margin-left: 21px;margin-top: 15px;">
                        <a-textarea  :placeholder="l('One_mailbox_per_row')" v-model="Global_Configuration" :rows="10" />
                    </a-col>
                </a-row>
            </a-config-provider>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>

    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import {MailTemplateServiceProxy,MailTemplateEditDto,CreateOrUpdateMailTemplateInput} from '../../../../shared/service-proxies';
    import Editor from "../../../../components/editor/editor";
    import VueClipboard from 'vue-clipboard2'

    let _this;

    export default {
        name: "Global-Configuration",
        mixins: [ModalComponentBase],
        components: {
            Editor,
            VueClipboard
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this.MailTemplateServiceProxy = new MailTemplateServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.getData();
        },
        data() {
            return {
                zh_CN,
                Global_Configuration:undefined,
				isclick: true,
            }
        },
        methods: {
            getData() {
                this.isLoading = true;
                this.MailTemplateServiceProxy.getGlobalConfiguration(
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.Global_Configuration = res.replaceAll(",", "");
                })
            },
            save() {
				if(this.isclick) {
					this.isclick = false;
					let txt = this.Global_Configuration.replace(/[\r\n]/g, ",\n");
					this.MailTemplateServiceProxy.globalConfiguration(
					    {emailStr:txt}
					).finally(() => {
					    // this.close()
					}).then(res => {
						this.isclick = true;
					    this.success(true)
					})
				}
            },
        }
    }
</script>

<style>
    .input {
        width: 200px;
    }
</style>
